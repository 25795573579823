import type { SelectListType } from '@flowardco/fui-input-field';
import type { CountryAPIModel } from '@flowardco/flib-models';

export interface FormatCountryListProps {
  countries: CountryAPIModel[];
  isArabic: boolean;
  __TK: any;
  withCode?: boolean;
}
export type ExtendedSelectListType = SelectListType & {
  isoCode?: string;
};

export const formatCountryList = ({
  countries = [],
  __TK,
  isArabic,
  withCode = true,
}: FormatCountryListProps): ExtendedSelectListType[] => {
  const countryList =
    countries?.map((country: CountryAPIModel) => {
      const code = isArabic ? `(${country?.Code}+)` : `(+${country?.Code})`;
      const label = withCode
        ? `${__TK(country?.Name, country?.NameAR)} ${code}`
        : `${__TK(country?.Name, country?.NameAR)}`;
      return {
        label,
        value: country?.Id,
        isoCode: country?.ISOCode,
        countryCode: country?.Code,
      };
    }) || [];
  return countryList;
};
