import React, { useRef, useState, useCallback } from 'react';
import cx from 'clsx';
import debounce from 'lodash/debounce.js';
import { InputField } from '@flowardco/fui-input-field';
import { LoadingSpinner } from '@flowardco/fui-loading-spinner';
import { useClickOutside } from '@flowardco/flib-hooks';
import type { PostalCodeItemProps } from '@flowardco/flib-models';
import { PostalCodeList } from './PostalCodeList';
import type { HeadersProps } from './usePostalApi';
import usePostalApi from './usePostalApi';

export interface SearchPostalCodeProps {
  className?: string;
  name?: string;
  placeholder?: string;
  headers?: HeadersProps;
  onPostCodeSelected?: (item: PostalCodeItemProps) => void;
  onPostalCodeCheckError?: (error: any) => void;
  onPostalCodeCheckSuccess?: (res: any) => void;
  onPostalCodeChange?: (value: any) => void;
  validateDelivery?: boolean;
  isLondonOnly?: boolean;
  validateParams?: any;
  label?: string;
  required?: boolean;
  value?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  hasError?: boolean;
}

export const SearchPostalCode: React.FC<SearchPostalCodeProps> = ({
  className = '',
  name = 'postalCode-input',
  placeholder = 'Start typing a postcode, address or company here',
  headers,
  label = '',
  onPostCodeSelected,
  onPostalCodeCheckError,
  onPostalCodeCheckSuccess,
  onPostalCodeChange,
  validateDelivery = false,
  isLondonOnly = false,
  validateParams = {},
  required = false,
  value = '',
  inputProps = {},
  hasError = false,
}) => {
  const wrapperRef = useRef(null);
  const [showAutoComplete, setShowAutoComplete] = useState(true);
  const [fieldValue, setFieldValue] = useState(value);
  const { state, getUKPostalCodes, checkPostalCodeDelivery } = usePostalApi({
    headers,
    onPostalCodeCheckError,
    onPostalCodeCheckSuccess,
  });
  const { isLoading, postalCodes } = state;

  const debouncedSearch = useCallback(
    debounce((key, container = '') => {
      getUKPostalCodes(key, container);
    }, 550),
    []
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onPostalCodeChange) {
      onPostalCodeChange(e.target.value);
    }
    setFieldValue(e.target.value);
    const val = e?.target?.value?.trim() || '';
    if (!val) return;
    setShowAutoComplete(true);
    debouncedSearch(val, '');
  };

  const selectPostalCode = async (item: PostalCodeItemProps) => {
    if (item.Type !== 'Address') {
      debouncedSearch(fieldValue, item.Id);
    } else {
      if (onPostCodeSelected) {
        onPostCodeSelected(item);
      }
      const selectedPostal = item?.Description || '';
      setFieldValue(selectedPostal || '');
      setShowAutoComplete(false);
      if (validateDelivery) {
        checkPostalCodeDelivery(
          item?.Id,
          item.Description,
          isLondonOnly,
          validateParams
        );
      }
    }
  };

  useClickOutside(wrapperRef, () => {
    setShowAutoComplete(false);
  });

  return (
    <div
      className={cx('fui-relative', className)}
      ref={wrapperRef}
      data-testid='TestId__SearchPostalCode'
    >
      <InputField
        name={name}
        wrapperClassName='fui-relative'
        inputFieldClass={cx(
          'fui-text-base placeholder:fui-text-sm',
          hasError ? 'fui-border-error-800' : ''
        )}
        placeholder={placeholder}
        value={fieldValue}
        onChange={onChange}
        label={label}
        required={required}
        inputProps={inputProps}
      />
      {isLoading && (
        <LoadingSpinner
          iconClassName='fui-w-20px fui-h-35px'
          className='fui-absolute fui-right-0 fui-top-[-14px] fui-pr-10px'
          logo={false}
        />
      )}
      {showAutoComplete && postalCodes.length > 0 && (
        <PostalCodeList postalCodes={postalCodes} onSelect={selectPostalCode} />
      )}
    </div>
  );
};
