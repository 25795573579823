import { extractMonthName, formatPrice } from '@flowardco/flib-util';
import { SlotStatusLabelEnum } from '@flowardco/flib-models';
import { redirectToRoute } from '@flowardco/flib-helpers';

export const getCallBackUrl = () => {
  try {
    const urlFromQuery = decodeURI(
      new URLSearchParams(window.location.search).get('url') || ''
    );
    const regex = new RegExp('^' + window.location.origin + '(/|$)', 'i');
    const containsCurrentOrigin = regex.test(urlFromQuery);
    if (containsCurrentOrigin) {
      return urlFromQuery;
    }
    return null;
  } catch {
    return null;
  }
};
export const getLoginStepFromUrl = () => {
  try {
    const stepFromQuery = decodeURI(
      new URLSearchParams(window.location.search).get('step') || ''
    );
    return stepFromQuery;
  } catch {
    return null;
  }
};
export const updateStep = (newStep: any) => {
  const currentUrl = new URL(window.location.href);

  // Manually construct the query string
  const queryString = `step=${newStep}`;

  // Construct the new URL
  const newUrl = `${currentUrl.pathname}?${queryString}`;

  // Update the URL without reloading the page
  window.history.replaceState({}, '', newUrl);
};

export const formatDeliverySlotResponse = ({
  data = {},
  __T,
}: {
  data: any;
  __T: any;
}) => {
  return {
    isNationwide: data?.isNationwide,
    avilableSlots:
      data?.avilableSlots?.map((item: any) => ({
        ...item,
        value: item?.id,
        statusName: getStatus(item?.status, __T),
        disabled: getStatus(item?.status, __T) !== null,
      })) || [],
  };
};

export const getStatus = (status: number, __T: any) => {
  let statusName;
  switch (status) {
    case 0:
      statusName = null;
      break;
    case 1:
      statusName = __T(SlotStatusLabelEnum.Busy);
      break;
    case 2:
      statusName = __T(SlotStatusLabelEnum.NotAvailable);
      break;
    default:
      statusName = null;
      break;
  }
  return statusName;
};

export const formatDeliveryDatesResponse = ({
  data = [],
  __TK,
  __T,
}: {
  data?: any;
  __TK?: any;
  __T?: any;
}) => {
  const disabledDates: any[] = data?.reduce((acc: any, itm: any) => {
    if (itm?.disabledDays) {
      return acc.concat(itm?.disabledDays);
    }
    return acc;
  }, []);
  const formattedResponse = data
    ?.map((item: any) => {
      const formattedDate = item?.webDateString?.split('-');
      return {
        ...item,
        day: formattedDate?.[2],
        month: __T(extractMonthName(item?.webDateString)),
        disabled: disabledDates?.includes(item?.date) && item?.picker !== true,
        title: __TK(item?.title, item?.titleAr),
      };
    })
    .filter((item: any) => !item.disabled);
  return formattedResponse;
};

export const formatDeliverySlotUkResponse = (data: any, currency: string) => {
  return {
    isNationwide: data?.isNationwide,
    avilableSlots: data.avilableSlots.map((item: any) => ({
      ...item,
      formatedPrice: item?.price !== 0 ? formatPrice(item?.price, currency) : 0,
    })),
  };
};
export const onLanguageButtonClick = (
  e: React.MouseEvent,
  inArabic: boolean,
  isLocaleOnly: boolean
) => {
  e.preventDefault();
  const urlSuffix = isLocaleOnly ? '/' : '-';
  const currentUrl = window.location.pathname.split(
    `/${inArabic ? 'ar' : 'en'}${urlSuffix}`
  )?.[1];

  let locationSearch = window.location.search;
  const searchParams = new URLSearchParams(window.location.search);
  const query = searchParams.get('q');

  if (query) {
    searchParams.set('q', decodeURI(query));
    locationSearch = `?${searchParams.toString()}`;
  }

  const newUrl = `/${inArabic ? 'en' : 'ar'}${urlSuffix}`.concat(
    currentUrl,
    locationSearch
  );

  redirectToRoute(newUrl, true);
};
