import React, { useEffect, useState } from 'react';
import { InputField } from '@flowardco/fui-input-field';
import { SearchSelect } from '@flowardco/fui-search-select/src';
import cx from 'clsx';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import type { CountryCode } from 'libphonenumber-js/max';
import type { SelectListType } from '@flowardco/fui-input-field';
export type ExtendedSelectListType = SelectListType & {
  isoCode?: string;
};

export interface PhoneNumberProps {
  phoneCountryCodesList: ExtendedSelectListType[];
  handleCountrySelectChange: (e: any) => void;
  selectPlaceHolder?: string;
  selectId: string;
  selectName?: string;
  inputId: string;
  inputName?: string;
  inputTestid?: string;
  wrapperClassName?: string;
  selectWrapperClassName?: string;
  inputWrapperClassName?: string;
  selectContainerClass?: string;
  selectErrorMessage: string | undefined;
  inputErrorMessage: string | undefined;
  inputErrorMessageClass?: string | undefined;
  inputOnChange?: (e: any) => void;
  inputOnBlur?: (e: any) => void;
  selectValue?: string | number;
  inputValue?: string;
  selectLabel?: string;
  inputLabel?: string;
  register?: any;
  placeholderLabel?: string;
  countrySelectLabel?: string;
  __T?: (value: string) => any;
  validate?: boolean;
}

export const PhoneNumber: React.FC<PhoneNumberProps> = ({
  phoneCountryCodesList,
  selectPlaceHolder,
  selectId,
  selectName,
  inputId,
  inputName,
  inputTestid,
  handleCountrySelectChange,
  wrapperClassName,
  selectContainerClass,
  selectWrapperClassName,
  inputWrapperClassName,
  inputOnChange,
  inputOnBlur,
  inputErrorMessage,
  selectErrorMessage,
  selectValue,
  inputValue,
  selectLabel,
  inputLabel,
  register,
  __T,
  validate = false,
}) => {
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const isoCountryCode = phoneCountryCodesList?.find(
    (itm: any) => itm?.value === selectValue
  )?.isoCode;
  const validatePhoneNumber = (
    number: string,
    countryCode: string | undefined
  ) => {
    if (!countryCode && __T) {
      setPhoneError(__T('Please select a country code.'));
      return false;
    }

    const parsedNumber = parsePhoneNumberFromString(
      number,
      countryCode as CountryCode
    );

    if (!parsedNumber || !parsedNumber.isValid()) {
      if (__T) {
        setPhoneError(__T('Invalid phone number.'));
      }
      return false;
    }

    setPhoneError(null);
    return true;
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value;
    if (inputOnChange) inputOnChange(e);

    if (selectValue && validate)
      validatePhoneNumber(number, isoCountryCode as CountryCode);
  };

  const handlePhoneBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (inputOnBlur) inputOnBlur(e);
    if (validate) {
      validatePhoneNumber(e.target.value, isoCountryCode as CountryCode);
    }
  };
  useEffect(() => {
    if (inputValue && validate) {
      validatePhoneNumber(inputValue, isoCountryCode as CountryCode);
    }
  }, [selectValue]);
  return (
    <div
      data-testid='TestId__PhoneNumber'
      className={cx(
        wrapperClassName,
        'fui-mt-2 fui-flex fui-flex-nowrap fui-items-start fui-justify-between fui-pb-3'
      )}
    >
      <div
        className={cx(
          selectWrapperClassName,
          'fui-block fui-w-1/2 ltr:fui-pr-2 rtl:fui-pl-2 md:fui-w-2/5'
        )}
      >
        <SearchSelect
          containerClass={cx(selectContainerClass, 'fui-z-20 fui-mb-2')}
          required={true}
          label={selectLabel}
          selectClassName='fui-w-full'
          placeHolder={selectPlaceHolder}
          id={selectId}
          list={phoneCountryCodesList}
          name={selectName}
          onChange={handleCountrySelectChange}
          errorMessage={selectErrorMessage}
          value={selectValue}
          testId='TestId__CountryId'
        />
      </div>
      <div
        className={cx(
          inputWrapperClassName,
          'fui-block fui-w-1/2 ltr:fui-pl-2 rtl:fui-pr-2 md:fui-w-3/5'
        )}
      >
        <InputField
          label={inputLabel}
          required={true}
          inputFieldClass='fui-w-full'
          id={inputId}
          name={inputName}
          onBlur={handlePhoneBlur}
          onChange={handlePhoneNumberChange}
          errorMessage={phoneError || inputErrorMessage}
          value={inputValue}
          testId={inputTestid}
          type={'tel'}
          maxLength={25}
          register={register}
        />
      </div>
    </div>
  );
};
