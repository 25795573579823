import React from 'react';
import cx from 'clsx';

export interface AutoCompletePlacesProps extends React.ComponentProps<'input'> {
  searchInputPlaceholder?: string;
  searchInputName?: string;
  onMyLocationClick?: () => void;
}

export const AutoCompletePlaces: React.FC<AutoCompletePlacesProps> = ({
  searchInputPlaceholder = 'Search',
  searchInputName = 'SearchAutoComplete',
  onMyLocationClick,
  ...inputProps
}) => {
  return (
    <div
      className='fui-absolute fui-bottom-auto fui-left-0 fui-right-0 fui-top-0 fui-z-100 fui-flex fui-flex-nowrap fui-justify-between fui-p-2'
      data-testid='TestId__AutoCompletePlaces'
    >
      <input
        type='text'
        name={searchInputName}
        id={searchInputName}
        placeholder={searchInputPlaceholder}
        className={cx(
          'fui-h-10 fui-w-full fui-border fui-border-gray-400 fui-bg-white fui-shadow-down fui-outline-none'
        )}
        data-testid='TestId__SearchAutoCompleteInput'
        {...inputProps}
      />
      <button
        type='button'
        className='fui-inline-flex fui-h-10 fui-w-10 fui-justify-center fui-rounded-full fui-bg-white fui-p-2 fui-align-middle fui-shadow-down ltr:fui-ml-2 rtl:fui-mr-2'
        onClick={onMyLocationClick}
        data-testid='TestId__MyLocationButton'
      >
        <img
          src={'/assets/cursor.png'}
          alt='cursor'
          className='fui-w-full fui-rotate-90'
        />
      </button>
    </div>
  );
};
