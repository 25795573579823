import React, { useState } from 'react';
import {
  InputField,
  TextareaField,
  Checkbox,
} from '@flowardco/fui-input-field';
import { useFormik } from 'formik';
import { Modal } from '@flowardco/fui-modal';
import { QRCodeSVG } from 'qrcode.react';
import * as Yup from 'yup';
import { twMerge } from 'tailwind-merge';
import { useValidations } from '@flowardco/flib-hooks';
import type { CardMessageProps, FormValues } from './CardMessage.types';
import IconLogoIcon from '@flowardco/fui-icons/src/IconLogoIcon';

const MAX_LENGTH = 192;
export const CardMessage: React.FC<CardMessageProps> = ({
  __T,
  onSubmitCard,
  isBraille = false,
  initialValues,
  className = '',
  trackPreviewClicked,
  cardImageUrl = '',
  qrCodeLink,
  showNewMsg = false,
}) => {
  const [previewMessage, setPreviewMessage] = useState<boolean>(false);
  const { validations } = useValidations(__T);

  const handlePreviewMessage = () => {
    if (!previewMessage) {
      setPreviewMessage(true);
      if (trackPreviewClicked) {
        trackPreviewClicked();
      }
    } else {
      setPreviewMessage(false);
    }
  };
  const handleFormChange = (values: FormValues) => {
    if (onSubmitCard && values) {
      onSubmitCard(values);
    }
  };
  const validationSchema = Yup.object({
    cardTo: validations.cardName,
    cardMessage: validations.cardMessage,
    cardFrom: validations.cardName,
    feelingLink: validations.feelingLink,
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: handleFormChange,
    validationSchema,
    validateOnChange: true,
  });
  const inputFormClass = twMerge(
    'fui-mb-1',
    'fui-block',
    'fui-w-full',
    'fui-border-0',
    'fui-text-base',
    '!fui-rounded-none'
  );
  const remainingCharacters = MAX_LENGTH - formik.values.cardMessage?.length;

  const getMessageTitle = () => {
    if (!showNewMsg) {
      return formik.values.feelingLink
        ? __T('A special message')
        : __T('Say thank you');
    } else {
      return formik.values.feelingLink
        ? __T('A surprise waiting for you!')
        : __T('Did you enjoy your gift?');
    }
  };

  const getMessageDesc = () => {
    if (!showNewMsg) {
      return formik.values.feelingLink
        ? __T('Scan the QR code to view a special message for you.')
        : __T('Scan the QR code and express your gratitude to the sender.');
    } else {
      return formik.values.feelingLink
        ? __T(
            'Scan the QR code to reveal a special message for you. Feel free to respond and express your true feelings'
          )
        : __T(
            'Scan the  QR code to send a heartfelt message to the sender and express your true feelings'
          );
    }
  };

  const messageTitle = getMessageTitle();
  const messageDesc = getMessageDesc();
  return (
    <div
      data-testid='TestId__CardMessage'
      className={twMerge(
        'fui-flex fui-w-full fui-flex-wrap fui-rounded fui-border fui-px-3',
        className
      )}
    >
      <div className='fui-flex fui-w-full fui-justify-between fui-text-sm fui-font-medium fui-text-gray-400'>
        <h3 className='fui-mb-2 fui-text-base fui-font-bold fui-text-black-900'>
          {__T('Add your message')}
        </h3>
        <p className={remainingCharacters === 0 ? 'fui-text-error-800' : ''}>
          {__T(`{value} Characters left`, {
            value: remainingCharacters,
          })}
        </p>
      </div>
      <form
        id='cardMessage'
        data-testid='TestId__CardMessageForm'
        className='fui-w-full'
        onSubmit={formik.handleSubmit}
      >
        <div className='fui-mx-0 fui-flex fui-w-full fui-flex-wrap fui-rounded fui-border fui-border-gray-325 fui-p-3'>
          <div className='fui-w-full md:fui-w-1/2'>
            <InputField
              type='text'
              placeholder={__T('To')}
              name='cardTo'
              id='cardTo'
              value={formik.values.cardTo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.errors.cardTo && formik.touched.cardTo
                  ? formik.errors.cardTo
                  : undefined
              }
              inputFieldClass={twMerge(
                inputFormClass,
                'fui-border-b',
                'fui-border-gray-325'
              )}
              inputProps={{ maxLength: 30 }}
            />
          </div>

          <div className='fui-w-full'>
            <TextareaField
              textAreaClassName={inputFormClass}
              value={formik.values.cardMessage || ''}
              errorMessage={
                formik.errors.cardMessage && formik.touched.cardMessage
                  ? formik.errors.cardMessage
                  : undefined
              }
              onChange={formik.handleChange}
              inputProps={{ onBlur: formik.handleBlur }}
              name='cardMessage'
              id='cardMessage'
              placeholder={__T('card message')}
              maxLength={MAX_LENGTH}
            />
          </div>

          <div className='fui-w-full md:fui-w-1/2'>
            <InputField
              type='text'
              placeholder={__T('From')}
              value={formik.values.cardFrom || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={
                formik.errors.cardFrom && formik.touched.cardFrom
                  ? formik.errors.cardFrom
                  : undefined
              }
              name='cardFrom'
              id='cardFrom'
              inputFieldClass={twMerge(
                inputFormClass,
                'fui-border-b',
                'fui-border-gray-325'
              )}
              inputProps={{ maxLength: 30 }}
            />
          </div>
        </div>
        {isBraille ? (
          <div className='flex flex-wrap' data-testid='TestId__CardBraille'>
            <div className='fui-my-4 fui-w-full fui-rounded fui-bg-gray-75 fui-p-3'>
              <Checkbox
                type='checkbox'
                label={__T('Print this message in braille.')}
                labelClass='md:fui-w-full fui-font-medium !fui-text-base'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='isBraillePrint'
                id='braillePrint'
                checked={formik.values.isBraillePrint}
                defaultChecked={formik.values.isBraillePrint}
              />
              <p className='fui-mt-2 fui-text-base fui-text-black-900'>
                {__T(
                  'Braille is a system of raised dots that can be read with the fingers by individuals who are blind or who have low vision.'
                )}
              </p>
            </div>
          </div>
        ) : null}

        <div className='fui-mb-6 fui-mt-3 fui-w-full fui-p-0'>
          <label htmlFor='shareLink' className='fui-text-base fui-font-bold'>
            {__T('Share your feelings')}
          </label>
          <div className='fui-relative fui-mt-2 fui-flex fui-w-full fui-border fui-border-gray-350'>
            <span className='fui-border-gray-350 fui-p-3 ltr:fui-border-r rtl:fui-border-l'>
              <img
                src='https://floward.imgix.net/web/Files/attachment/path-177-637538055967833467.svg?w=20&h=20&fit=crop&auto=format'
                className='fui-h-4 fui-w-5'
                alt='share-link'
                width='18'
                height='18'
              />
            </span>
            <InputField
              type='text'
              placeholder={__T('paste a link to a song or video')}
              name='feelingLink'
              id='shareLink'
              value={formik.values.feelingLink}
              onChange={formik.handleChange}
              inputFieldClass={twMerge(inputFormClass)}
              wrapperClassName='fui-w-full'
              onBlur={formik.handleBlur}
              errorMessage={
                formik.errors.feelingLink && formik.touched.feelingLink
                  ? formik.errors.feelingLink
                  : undefined
              }
              errorMessageClass='fui-absolute'
            />
          </div>
        </div>
      </form>

      <div className='fui-w-full fui-rounded fui-bg-navy-250 fui-py-3 ltr:fui-text-right rtl:fui-text-left'>
        <button
          className='pointer fui-flex fui-w-full fui-items-center fui-justify-center fui-gap-2 fui-text-right fui-text-sm fui-font-medium  fui-text-navy-500 rtl:fui-text-left'
          onClick={handlePreviewMessage}
        >
          {__T('Preview Message')}
        </button>
      </div>
      {previewMessage ? (
        <Modal
          data-testid='TestId__CardModal'
          wrapperClass='!fui-p-0 fui-z-20'
          className={twMerge(
            ' fui-w-full fui-bg-white fui-px-9px fui-pt-16px md:fui-p-4 md:fui-px-15px',
            cardImageUrl === ''
              ? 'md:fui-w-[449px]'
              : 'fui-gap-[17px] md:fui-max-w-[866px]'
          )}
          onBackdropClose={handlePreviewMessage}
          onIconClose={handlePreviewMessage}
        >
          <div className='fui-flex fui-w-full fui-flex-col fui-gap-4 fui-text-center  md:fui-flex-row'>
            {cardImageUrl !== '' ? (
              <div className='fui-relative fui-border-0 md:fui-w-1/2 '>
                <img
                  src={cardImageUrl}
                  alt='floward-card'
                  className='fui-aspect-square fui-h-[409px] fui-w-[409px] fui-rounded-2xl'
                />
                <div className='fui-w-full fui-bg-white fui-py-10px fui-text-center fui-text-md fui-font-semibold fui-text-black-700'>
                  {__T('Back side')}
                </div>
              </div>
            ) : null}
            <div
              className={twMerge(
                'flex fui-relative fui-flex fui-flex-col fui-items-center fui-justify-start md:fui-w-1/2',
                cardImageUrl === '' ? '!fui-w-full' : ''
              )}
            >
              <div className='fui-flex fui-h-[409px] fui-flex-col fui-items-center fui-justify-between fui-rounded-2xl fui-border fui-border-[#F0E9E0] fui-bg-white fui-px-18px md:fui-w-[409px]'>
                <IconLogoIcon className='fui-mx-auto fui-mb-20px fui-mt-24px fui-h-[40px] fui-w-[40px] fui-text-[#B98D81]' />
                <div className='fui-min-h-[194px]'>
                  {formik.values.cardTo && (
                    <h3 className='fui-text-18px fui-mb-20px fui-font-enFounderRegular fui-text-lg fui-font-normal -fui-tracking-[0.36px] fui-text-[#1B1D21] rtl:fui-font-cardMessageAr'>
                      {formik.values.cardTo}
                    </h3>
                  )}

                  <p className='fui-text-20px fui-m-auto fui-mb-20px  fui-w-[90%] fui-break-words fui-font-enFounderRegular fui-font-normal fui-leading-[21px] fui-text-[#1B1D21] rtl:fui-font-cardMessageAr'>
                    {formik.values.cardMessage}
                  </p>
                  {formik.values.cardFrom && (
                    <h3 className='fui-text-18px fui-mb-45px fui-font-enFounderRegular fui-text-lg fui-font-normal fui-text-[#1B1D21] rtl:fui-font-cardMessageAr'>
                      {formik.values.cardFrom}
                    </h3>
                  )}
                </div>

                {qrCodeLink ? (
                  <div className='fui-m-auto fui-mb-19px fui-mt-auto fui-flex fui-w-full fui-items-center fui-gap-15px fui-rounded-xl fui-bg-[#F0E9E0] fui-px-14px fui-py-10px '>
                    <div>
                      <QRCodeSVG
                        value={qrCodeLink}
                        width='66'
                        height='64'
                        className='fui-inline-flex'
                      />
                    </div>
                    <div className='fui-text-left rtl:fui-text-right'>
                      <p className='fui-mb-9px fui-font-enFounderRegular fui-text-[14px] fui-font-normal fui-text-[#00313C] rtl:fui-font-cardMessageAr'>
                        {messageTitle}
                      </p>
                      <p className='fui-font-["Founders Grotesk"] fui-font-enFounderRegular fui-text-[12px] fui-font-normal fui-leading-[15.6px] -fui-tracking-[.26px] fui-text-[#00313C] rtl:fui-font-cardMessageAr'>
                        {messageDesc}
                      </p>
                      <p></p>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className=' fui-w-full fui-bg-white fui-py-10px fui-text-center fui-text-md fui-font-semibold fui-text-black-700'>
                {__T('Front side')}
              </div>
            </div>
          </div>
          {formik.values.isBraillePrint ? (
            <div className='fui-bg-navy75 fui-mt-3 fui-w-full fui-rounded fui-p-2'>
              <p className='fui-small-font fui-mb-0 fui-text-sm'>
                {__T('Your message will also be printed in braille.')}
              </p>
            </div>
          ) : null}
        </Modal>
      ) : null}
    </div>
  );
};
