import {
  getCountryList,
  getLeaveLocations,
  saveRecipientAddress,
} from '@/api/address.service';
import { PageTitle } from '@/components/PageTitle';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { useQuery } from 'react-query';
import { Button } from '@flowardco/ui/Button';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValidations } from '@flowardco/flib-hooks';
import { RecipientContactInfo } from '@/components/AddressForm/RecipientContactInfo';
import { AddressType } from '@flowardco/flib-models';
import { useNavigate } from 'react-router-dom';
import { addToast } from '@flowardco/flib-util';
import { SET_REFETCH_ORDER } from '@/Context/LocalAppContext';
import { SelectField, TextareaField } from '@flowardco/fui-input-field';
import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import { AddressWithMap } from '@/components/AddressForm/AddressWithMap';
import { AddressWithPostalCode } from '@/components/AddressForm/AddressWithPostalCode';
import { isValidPhoneNumber } from 'libphonenumber-js/max';

const AddNewAddress = () => {
  const { appState, appDispatch } = useLocalApp();
  const { __T, __TK } = useLocalTranslator();
  const { validations } = useValidations(__T);
  const navigate = useNavigate();
  const { orderData, lang } = appState;
  const IS_UK = orderData?.operation?.countryId === 8;
  const { data: countryData } = useQuery(
    ['getCountryList', appState?.orderData?.order?.id],
    () => {
      return getCountryList();
    },
    {
      cacheTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const { data: leaveLocationsList } = useQuery(
    ['getLeaveLocation', appState?.orderData?.order?.id],
    () => {
      return getLeaveLocations(appState);
    },
    {
      cacheTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const basicValidationSchema = Yup.object({
    recipientName: validations.name,
    recipientPhone: validations.phone,
    addressLine: validations.addresLine1,
    addressLineTwo: validations.notrequired,
    countryId: validations.country,
  });

  const ukValidationSchema = basicValidationSchema.shape({
    LeaveLocation: validations.notrequired,
    postalCode: validations.requiredField,
  });
  const gccValidationSchema = basicValidationSchema.shape({
    areaId: validations.areaId,
    lat: validations.requiredField,
    lng: validations.requiredField,
  });

  const initialValues = {
    countryId: orderData?.orderAddress?.countryId || '',
    userId: orderData?.order?.userId || '',
    recipientName: orderData?.orderAddress?.recipientName || '',
    recipientPhone: orderData?.orderAddress?.recipientPhone || '',
    SaveAddress: true,
    type: AddressType.APARTMENT,
    LeaveLocation: orderData?.order?.leaveLocation || -1,
    addressLine: '',
    lat: '',
    lng: '',
    postalCode: '',
    areaId: '',
    addressLineTwo: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    clearErrors,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(IS_UK ? ukValidationSchema : gccValidationSchema),
    defaultValues: initialValues,
    mode: 'all',
  });

  const onSubmit = async (values: any): Promise<void> => {
    try {
      const countryObj =
        countryData.find((itm: any) => itm.Id == values.CountryId) || '';
      if (
        !isValidPhoneNumber(values?.RecipientPhone || '', {
          defaultCallingCode: countryObj?.Code,
        })
      ) {
        addToast(__T('invalid phone number.'), 'error');
        return;
      }
      const res = await saveRecipientAddress({
        data: values,
        appState,
      });
      if (res?.sucsess) {
        addToast(res?.message);
        appDispatch({ type: SET_REFETCH_ORDER, payload: Date.now() });
        navigate(`/${lang}/`);
      }
    } catch (error: any) {
      addToast(
        error?.response?.data?.message ||
          error?.message ||
          'Some error occured',
        'error'
      );
      errorLoggerV2('AddNewAddressPage::onSubmit', error);
    }
  };
  return (
    <div>
      <PageTitle title={__T('Add New Address')} showBackButton />
      <div className='fui-px-4.5 fui-py-4'>
        <form
          id='recipientDetailsForm'
          method='post'
          onSubmit={handleSubmit(onSubmit)}
          className='fui-rounded-md fui-bg-white fui-p-3 fui-shadow-sm'
        >
          <RecipientContactInfo
            translators={{ __T, __TK }}
            countries={countryData}
            register={register}
            errors={errors}
            isArabic={lang === 'ar'}
            setValue={setValue}
            clearErrors={clearErrors}
            getValues={getValues}
            watch={watch}
          />
          {/* Show Map if GCC or Postal code if UK */}
          {IS_UK ? (
            <>
              <AddressWithPostalCode
                setValue={setValue}
                getValues={getValues}
                register={register}
                errors={errors}
                control={control}
                orderData={orderData}
              />
            </>
          ) : (
            <>
              <AddressWithMap
                setValue={setValue}
                getValues={getValues}
                register={register}
                errors={errors}
                control={control}
              />
            </>
          )}
          <TextareaField
            wrapperClassName='fui-mt-4'
            name='addressLineTwo'
            id='addressLineTwo'
            placeholder={__T(
              'Extra address details (Optional) provide details such as building description'
            )}
            register={register}
            errorMessage={errors?.addressLineTwo?.message || undefined}
            testId='TestId__TextareaFieldExtraDetails'
          />
          {orderData?.operation?.countryId === 8 ? (
            <SelectField
              label={__T('What to do if nobody is home?')}
              id='LeaveLocation'
              name='LeaveLocation'
              blankOption={__T('Select from list')}
              required={false}
              selectClassName='fui-w-full fui-mb-4'
              containerOverrideClass='fui-col-span-1'
              list={leaveLocationsList}
              onChange={(e) => setValue('LeaveLocation', e.target.value)}
              errorMessage={errors?.LeaveLocation?.message || undefined}
              value={getValues('LeaveLocation')}
            />
          ) : (
            ''
          )}
          <div className='fui-block fui-py-2'>
            <Button
              label={__T('Add New Address')}
              buttonStyle='primary'
              size='sm'
              className='fui-w-full'
              type='submit'
              isProcessing={isSubmitting}
              disabled={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNewAddress;
